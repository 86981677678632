import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import {
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
} from '@material-ui/core'
import { telephoneMask } from 'js-essentials-functions'
import moment from 'moment'

import { translate } from 'locales'

import Pagination from '@material-ui/lab/Pagination'

// Components
import {
  Header,
  Card,
  Button,
  Loading,
  Footer,
  Search,
  Input,
} from 'components'
import * as Global from 'global/styles.global'
import useFetch from 'hooks/useFetch'

// Styles
import * as S from './styles'
const Patients = () => {
  const history = useHistory()
  const [numPage, setNumPage] = useState(1)
  const [name, setName] = useState('')
  const [date, setDate] = useState({
    start: '',
    end: '',
  })
  const [dataSearch, setDataSearch] = useState({
    start: '',
    end: '',
    name: '',
  })

  const dataStorage: any = window.localStorage.getItem('@StarBem:user')
  const dataParse = JSON.parse(dataStorage)

  const { data, isLoading } = useFetch({
    url: `/schedulings/ms/v1/doctor/${dataParse.id}/all-patients?page=${numPage}&limit=10&name=${name}&start=${date.start}&end=${date.end}`,
    options: {
      revalidateOnMount: true,
    },
  })

  return (
    <Global.Container>
      {isLoading && <Loading title={translate('patients.loading')} />}
      <Header />
      <S.ContentPatients>
        <div className="patients-top">
          <h1 className="title-patients">{translate('patients.patients')}</h1>
          <div className="search-patients">
            <Search
              name="search-patient"
              placeholder={translate('patients.searchPatients')}
              value={name}
              onChange={(e: any) =>
                setDataSearch({
                  ...dataSearch,
                  name: e.target.value,
                })
              }
            />
            <Button
              color="primary"
              onClick={() => {
                setNumPage(1)
                setName(dataSearch.name)
              }}
              type="button"
              title="Buscar"
            >
              {translate('patients.search')}
            </Button>
            <Button
              color="primary-light"
              onClick={() => {
                setDate({
                  start: '',
                  end: '',
                })
                setName('')
                setNumPage(1)
                setDataSearch({
                  start: '',
                  end: '',
                  name: '',
                })
              }}
              type="button"
              title="Limpar"
            >
              {translate('patients.clean')}
            </Button>
          </div>
        </div>
        <Card>
          <S.ContentHeader>
            <S.ContentInputs>
              <Input
                name="date"
                type="date"
                placeholder={translate('patients.dateInit')}
                label
                styles={{ marginLeft: 15 }}
                value={dataSearch.start}
                onChange={(e: any) =>
                  setDataSearch({
                    ...dataSearch,
                    start: moment(e.target.value).format('YYYY-MM-DD'),
                  })
                }
              />
              <Input
                name="date"
                type="date"
                value={dataSearch.end}
                onChange={(e: any) =>
                  setDataSearch({
                    ...dataSearch,
                    end: moment(e.target.value).format('YYYY-MM-DD'),
                  })
                }
                placeholder={translate('patients.dateEnd')}
                label
                styles={{ marginLeft: 15 }}
              />
              <Button
                color="primary-light"
                onClick={() => {
                  setDate({
                    start: dataSearch.start,
                    end: dataSearch.end,
                  })
                }}
                type="button"
                title="Filtrar"
                styles={{ marginLeft: 15 }}
              >
                {translate('patients.filter')}
              </Button>
            </S.ContentInputs>
          </S.ContentHeader>

          <S.TablePatients>
            <Table className="table-content" aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell width="25%">
                    {translate('patients.name')}
                  </TableCell>
                  <TableCell width="25%">
                    {translate('patients.cellphone')}
                  </TableCell>
                  <TableCell width="25%">
                    {translate('patients.lastAppointment')}
                  </TableCell>
                  <TableCell width="25%" />
                </TableRow>
              </TableHead>
              <TableBody>
                {data &&
                  data?.appointments.map((patient: any) => (
                    <TableRow key={patient.id}>
                      <TableCell
                        component="th"
                        scope="row"
                        className="primary-col"
                      >
                        {patient.user_name}
                      </TableCell>
                      <TableCell align="left">
                        {telephoneMask(patient.user_cellphone || '')}
                      </TableCell>
                      <TableCell align="left">
                        {moment
                          .utc(patient.from_date_time)
                          .format('DD/MM/YYYY')}
                      </TableCell>
                      <TableCell align="right">
                        <Button
                          type="button"
                          color="primary-light"
                          onClick={() =>
                            history.push(`/prontuarios/${patient.user_id}`)
                          }
                          size="small"
                          styles={{ width: 100 }}
                        >
                          {translate('patients.view')}
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
            {data?.total_page !== 1 && (
              <S.Pagination>
                <Pagination
                  count={data?.total_page}
                  color="secondary"
                  page={numPage}
                  onChange={(e, v) => setNumPage(v)}
                />
              </S.Pagination>
            )}
          </S.TablePatients>
        </Card>
      </S.ContentPatients>
      <Footer />
    </Global.Container>
  )
}

export default Patients
